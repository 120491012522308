<template>
  <div
    class="absolute bg-white shadow-md min-w-80 px-4 py-2"
    data-cy="email-template-link-modal"
    :style="positionStyle"
  >
    <div class="flex items-center space-x-6">
      <span class="text-headline">
        {{ $t("ORGANIZATION.EMAIL_TEMPLATES.ENTER_LINK") }}
      </span>
      <email-dynamic-field
        :model-value="updatedValue"
        data-cy="email-template-link-input"
        name="link"
        class="email-headless email-headless__xs flex-1 max-w-160"
        @update:model-value="handleUpdate"
      />
      <div class="flex items-center space-x-3">
        <button
          v-if="valueHasChanged"
          class="text-primary"
          data-cy="email-template-link-save"
          @click.prevent="saveValue"
        >
          {{ $t("COMMON.SAVE") }}
        </button>
        <button
          v-if="value"
          class="text-error"
          data-cy="email-template-link-delete"
          @click.prevent="emit('link:remove')"
        >
          {{ $t("COMMON.REMOVE") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import EmailDynamicField from "@/views/profile/EmailDynamicField.vue";
import { computed, ref } from "vue";
import {
  removeAttributesFromPayload,
  zeroWidthSpaceCharRegex
} from "@/helpers/textEditor";
import { stripHTMLTagsFromString } from "@/helpers/common";
import { getTagClass } from "@/lib/Quill";
const props = defineProps<{
  value: string;
  position: {
    bottom: number;
    left: number;
    right: number;
    top: number;
    height: number;
  };
}>();

const emit = defineEmits<{
  "value:save": [value: string];
  "link:remove": [];
}>();

const barHeight = 45;
const leftOffset = 20;

const constructDynamicFieldFromValue = (value: string, isSaved = false) => {
  const dynamicFieldWrapper = document.createElement("span");
  dynamicFieldWrapper.classList.add("mention");
  const dynamicField = document.createElement("span");
  dynamicField.classList.add(getTagClass(value));
  dynamicField.textContent = value;
  if (isSaved) {
    const contentEditableMarker = document.createElement("span");
    contentEditableMarker.contentEditable = "false";
    dynamicFieldWrapper.appendChild(contentEditableMarker);
    contentEditableMarker.appendChild(dynamicField);
  } else {
    dynamicFieldWrapper.appendChild(dynamicField);
  }
  return dynamicFieldWrapper.outerHTML;
};

const wrapInitialValue = (value: string) => {
  const paragraphWrapper = document.createElement("p");
  paragraphWrapper.innerHTML = value;
  return paragraphWrapper.outerHTML;
};

const updatedValue = ref(
  props.value.includes("[")
    ? constructDynamicFieldFromValue(props.value)
    : props.value
);
const initialValue = ref(
  props.value.includes("[")
    ? wrapInitialValue(constructDynamicFieldFromValue(props.value, true))
    : wrapInitialValue(updatedValue.value)
);

const handleUpdate = (value: string) => {
  updatedValue.value = value;
};

const saveValue = () => {
  const clearedValue = stripHTMLTagsFromString(
    removeAttributesFromPayload(updatedValue.value)
  );
  emit("value:save", clearedValue.replace(zeroWidthSpaceCharRegex, ""));
  updatedValue.value = "";
};

const valueHasChanged = computed(
  () =>
    updatedValue.value.replace(zeroWidthSpaceCharRegex, "") !==
    initialValue.value.replace(zeroWidthSpaceCharRegex, "")
);

const positionStyle = computed(() => {
  const topPositon = props.position.top + props.position.height + barHeight;
  const leftPosition = props.position.left - leftOffset;
  return {
    top: `${topPositon}px`,
    left: `${leftPosition}px`
  };
});
</script>
