<template>
  <loader :is-loading="loading || isSubmitting" />
  <div v-if="!loading" class="bg-white shadow-sm p-8 flex flex-col space-y-5">
    <div class="flex justify-between text-left relative">
      <div class="flex justify-between items-center w-full">
        <div class="flex items-center space-x-4 max-w-1/2">
          <back-button :route="backRoute" />
          <div class="truncate">
            <lf-h1 v-tooltip="activeEmailTemplate?.name">
              <template v-if="activeEmailTemplate?.name">
                {{
                  $t("ORGANIZATION.EMAIL_TEMPLATES.EDIT_TITLE", {
                    name: activeEmailTemplate.name
                  })
                }}
              </template>
              <template v-else>
                {{ $t("ORGANIZATION.EMAIL_TEMPLATES.CREATE_TITLE") }}
              </template>
            </lf-h1>
          </div>
        </div>
        <div class="flex space-x-4 items-center">
          <template-action-buttons
            class="flex space-x-4 items-stretch"
            form="emailTemplateForm"
            :is-edit="isEdit"
            :show-draft="
              activeEmailTemplate?.status !== TEMPLATE_MODES.published
            "
            :is-submitting="isSubmitting"
            :is-published="
              activeEmailTemplate?.status === TEMPLATE_MODES.published
            "
            @delete="showDeleteModal = true"
            @save-draft="saveTemplate(TEMPLATE_MODES.draft)"
            @publish="saveTemplate(TEMPLATE_MODES.published)"
          >
            <template v-if="lastUpdated" #lastUpdated>
              {{ lastUpdated }}
            </template>
          </template-action-buttons>
        </div>
      </div>
    </div>
  </div>

  <view-wrapper class="px-20 xl:px-50">
    <form id="emailTemplateForm" autocomplete="off">
      <lf-card has-padding class="py-6 space-y-4">
        <lf-h3>{{ $t("ORGANIZATION.EMAIL_TEMPLATES.DETAIL") }}</lf-h3>
        <lf-input
          data-cy="email-template-name"
          name="name"
          :placeholder="$t('COMMON.TEMPLATE_NAME')"
          :value="activeEmailTemplate?.name"
        />
        <lf-textarea
          data-cy="email-template-description"
          name="description"
          :placeholder="$t('COMMON.DESCRIPTION')"
          :value="activeEmailTemplate?.description || ''"
          :character-limit="TEXT_CHARACTER_LIMIT_DEFAULT"
          noresize
          rows="2"
        />
      </lf-card>
      <lf-card has-padding class="py-6 space-y-4">
        <div class="flex items-center justify-between">
          <lf-h3>{{ $t("ORGANIZATION.EMAIL_TEMPLATES.EMAIL_CONTENT") }}</lf-h3>
          <div class="flex items-center space-x-2">
            <icon-base
              v-if="emailWarningMessage"
              :icon="IconWarning"
              data-cy="cc-warning"
              class="text-orange-400"
              v-tooltip="emailWarningMessage"
            />
            <primary-button @click="sendTestEmail">
              {{ $t("ORGANIZATION.EMAIL_TEMPLATES.SEND_EMAIL") }}
            </primary-button>
          </div>
        </div>
        <div class="relative">
          <span
            v-if="values.subject"
            class="absolute top-0 left-0 -mt-3 text-xs leading-6 ml-2-5 px-1 z-1 bg-white"
          >
            {{ $t("ORGANIZATION.EMAIL_TEMPLATES.SUBJECT") }}
          </span>
          <email-dynamic-field
            :model-value="activeEmailTemplate?.subject || ''"
            name="subject"
            data-cy="email-template-subject"
            class="email-headless"
            :placeholder="$t('ORGANIZATION.EMAIL_TEMPLATES.SUBJECT')"
          />
        </div>
        <div class="relative">
          <span
            v-if="values.intro"
            class="absolute top-0 left-0 -mt-3 text-xs leading-6 ml-2-5 px-1 z-1 bg-white"
          >
            {{ $t("COMMON.TITLE") }}
          </span>
          <email-dynamic-field
            :model-value="activeEmailTemplate?.intro || ''"
            data-cy="email-template-intro"
            name="intro"
            class="email-headless"
            :placeholder="$t('COMMON.TITLE')"
          />
        </div>
        <div v-if="!loading" class="flex flex-col email-templates-edit">
          <email-dynamic-field
            data-cy="email-template-body"
            class="dynamic-field__body"
            name="body"
            :model-value="activeEmailTemplate?.body || ''"
            show-toolbar
          />
          <div class="bg-disabled p-2">
            {{ t("COMMON.TYPE") }}
            <span class="bg-white px-2 py-1">[</span>
            {{
              t(
                "ORGANIZATION.EMAIL_TEMPLATES.DYNAMIC_FIELD_SYMBOL_EXPLANATION"
              ).toLowerCase()
            }}
          </div>
        </div>
      </lf-card>
    </form>
  </view-wrapper>

  <confirm-modal
    v-if="showDeleteModal"
    :title="$t('ORGANIZATION.EMAIL_TEMPLATES.DELETE_TITLE')"
    :description="$t('ORGANIZATION.EMAIL_TEMPLATES.DELETE_DESCRIPTION')"
    :confirm-button="$t('COMMON.DELETE')"
    confirm-button-type="error"
    :close="() => (showDeleteModal = false)"
    @confirm="handleDelete"
  />
  <send-email-modal
    v-if="showSendEmailModal"
    :test-data="testData"
    @close="showSendEmailModal = false"
    @email:send="sendEmail"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { ref, computed, reactive, onBeforeUnmount, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { usePromiseWrapper } from "@/hooks/common";
import { formatDateCustom } from "@/helpers/formatting";
import useCustomizationStore from "@/stores/customization";
import { storeToRefs } from "pinia";
import { useForm } from "vee-validate";
import { callStoreAction } from "@/helpers/vee-validate";
import BackButton from "@/components/ui/buttons/BackButton.vue";
import TemplateActionButtons from "@/components/ui/TemplateActionButtons.vue";
import customizationApiService from "@/services/modules/customization";
import {
  ROUTE_EMAIL_TEMPLATES,
  ROUTE_EMAIL_TEMPLATES_EDIT
} from "@/router/routes";
import { TEXT_CHARACTER_LIMIT_DEFAULT } from "@/helpers/constants";
import { TEMPLATE_MODES } from "@/helpers/constants/emailTemplates";
import type { EmailTemplate, IClient } from "@/models/clients";
import router from "@/router";
import { useNotification } from "@/hooks/notifications";
import EmailDynamicField from "@/views/profile/EmailDynamicField.vue";
import { getObjectKeys } from "@/helpers/common";
import {
  removeAttributesFromPayload,
  zeroWidthSpaceCharRegex
} from "@/helpers/textEditor";
import type { EmailTemplateOptions } from "@/models/options";
import { useStore } from "vuex";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import SendEmailModal from "@/views/deals/components/emails/SendEmailModal.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import IconWarning from "@/components/icons/IconWarning.vue";

import type {
  SendEmailModalData,
  TestEmailData,
  TestEmailPayload
} from "@/models/emails";

enum EmailTemplateDynamicField {
  subject = "subject",
  intro = "intro",
  body = "body"
}

const values = reactive<EmailTemplate>({
  id: "",
  created_at: "",
  updated_at: "",
  name: "",
  description: "",
  intro: "",
  subject: "",
  body: "",
  footer: ""
});

const route = useRoute();
const { t } = useI18n();
const store = useCustomizationStore();
const { activeEmailTemplate } = storeToRefs(store);
const { getters, dispatch } = useStore();

const { showMessage } = useNotification();

const { fetchWrapper: getEmailTemplate, loading } = usePromiseWrapper(
  async () => {
    if (!isEdit.value) {
      store.activeEmailTemplate = { ...values };
      return;
    }
    const id = String(route.params.templateId);
    await store.getEmailTemplate(id);
  }
);

const backRoute = ref({
  name: ROUTE_EMAIL_TEMPLATES
});
const showDeleteModal = ref(false);
const showSendEmailModal = ref(false);
const testData = ref<TestEmailData | null>(null);

const emailTemplatesOptions = computed<EmailTemplateOptions | null>(
  () => getters["options/emailTemplates"]
);

const client = computed<IClient | null>(
  () => getters["auth/authClientSettings"]
);

const emailFieldsAreUpdated = computed(() =>
  getObjectKeys(EmailTemplateDynamicField).some((key) => {
    const currentValue = currentValues[key] ?? "";
    const activeTemplateValue = activeEmailTemplate.value?.[key] ?? "";
    return !areValuesEqual(currentValue, activeTemplateValue);
  })
);

const emailWarningMessage = computed(
  () =>
    (activeEmailTemplate.value?.id &&
      emailFieldsAreUpdated.value &&
      t("ORGANIZATION.EMAIL_TEMPLATES.UNSAVED_CHANGES_WARNING")) ||
    ""
);

const isEdit = computed(() => route.name === ROUTE_EMAIL_TEMPLATES_EDIT);
const lastUpdated = computed(() =>
  activeEmailTemplate.value?.updated_at
    ? `${t("LOGS.LAST_UPDATED")} ${formatDateCustom(
        activeEmailTemplate.value.updated_at,
        "yyyy-MM-dd HH:mm:ss"
      )}`
    : null
);

const clearDynamicValues = (value: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = value;
  const text = tempDiv.innerText || tempDiv.textContent;
  return text?.replace(zeroWidthSpaceCharRegex, "") || "";
};

const areValuesEqual = (value1: unknown, value2: unknown) => {
  if (typeof value1 !== "string" || typeof value2 !== "string") {
    return isEqual(value1, value2);
  }

  const firstClearValue = clearDynamicValues(value1);
  const secondClearValue = clearDynamicValues(value2);

  return firstClearValue === secondClearValue;
};

const { fetchWrapper: handleDelete } = usePromiseWrapper(async () => {
  if (!isEdit.value || !route.params.templateId) {
    return;
  }
  await customizationApiService.deleteEmailTemplate(
    route.params.templateId.toString()
  );
  await router.push(backRoute.value);
});

const {
  handleSubmit,
  isSubmitting,
  values: currentValues
} = useForm({
  initialValues: { ...values }
});

const { fetchWrapper: sendEmail } = usePromiseWrapper(
  async (data: SendEmailModalData) => {
    const payload: TestEmailPayload = {
      ...data,
      subject: activeEmailTemplate.value?.subject || "",
      intro: activeEmailTemplate.value?.intro || "",
      body: activeEmailTemplate.value?.body || ""
    };

    await customizationApiService.sendEmailTestData(payload);
    showSendEmailModal.value = false;
  }
);

const sendTestEmail = async () => {
  if (activeEmailTemplate.value?.id) {
    showSendEmailModal.value = true;
    return;
  }

  const values = {
    subject: currentValues.subject,
    body: currentValues.body,
    name: currentValues.name
  };

  const emptyFields: string[] = [];

  getObjectKeys(values).forEach((key) => {
    if (isEmpty(values[key])) {
      emptyFields.push(key);
    }
  });

  if (emptyFields.length) {
    showMessage(
      t(
        "ORGANIZATION.EMAIL_TEMPLATES.SEND_EMAIL_NO_DATA_MESSAGE",
        {
          fields: emptyFields.join(", ")
        },
        emptyFields.length
      ),
      "warning"
    );
    return;
  }

  await saveTemplate(TEMPLATE_MODES.draft);
  showSendEmailModal.value = true;
};

const saveTemplate = (
  status: (typeof TEMPLATE_MODES)[keyof typeof TEMPLATE_MODES]
) =>
  handleSubmit(async (values, actions) => {
    const payload: Partial<EmailTemplate> & { client_id?: string } = {
      ...values
    };

    if (isEdit.value && activeEmailTemplate.value?.id) {
      payload.id = activeEmailTemplate.value.id;
    }

    if (client.value?.id) {
      payload.client_id = client.value.id;
    }

    getObjectKeys(EmailTemplateDynamicField).forEach((key) => {
      payload[key] = removeAttributesFromPayload(payload[key]);
    });

    const actionName = isEdit.value
      ? "updateEmailTemplate"
      : "createEmailTemplate";

    payload.status = status;
    try {
      await callStoreAction(payload, actions, actionName, store);
    } catch (error) {
      // The error message will be displayed, no need to show 422 error
      if ((error as { message: string })?.message?.includes("422")) {
        return;
      }
      const message =
        (error as { message: string })?.message ?? t("COMMON.ERROR_OCCURRED");
      showMessage(message, "error");
    }
    if (!isEdit.value) {
      if (!store.activeEmailTemplate?.id) {
        return;
      }
      await router.push({
        name: ROUTE_EMAIL_TEMPLATES_EDIT,
        params: {
          templateId: store.activeEmailTemplate?.id
        }
      });
    }
  })();

getEmailTemplate();

onBeforeMount(async () => {
  if (isEmpty(emailTemplatesOptions.value)) {
    await dispatch("options/getEmailTemplatesOptions");
  }
  testData.value = await customizationApiService.getEmailTestData();
});

onBeforeUnmount(() => {
  store.activeEmailTemplate = null;
});
</script>
